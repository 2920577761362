.gdpr-cookieconsent-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    width: 100%;
    inset: 0;
    z-index: 9999;
    color: #fff;
    &::before {
        content: "";
        flex-grow: 1;
        background-color: var(--cookieconsent-overlay-color);
    }

    --cookieconsent-theme-color:  rgb(0, 163, 93);
    --cookieconsent-overlay-color: rgb(0 0 0 / 0.20);
    --cookieconsent-bg-color: rgb(44 49 53 / 0.95);

    --cookieconsent-group-bg-color: rgb(44 49 53 / 0.95);

    --cookieconsent-button-text-color: rgb(255 255 255);
    --cookieconsent-button-primary-color: var(--cookieconsent-theme-color);
    --cookieconsent-button-secondary-color: rgb(255 255 255);
    --cookieconsent-button-primary-text-color: var(--cookieconsent-button-text-color);
    --cookieconsent-button-secondary-text-color: rgb(0 0 0);
    --cookieconsent-button-borderradius: 4px;

    --cookieconsent-spacing: 0.5rem;
    --cookieconsent-width: 800px;

    --cookieconsent-focus-outline: white solid 2px;
    --cookieconsent-focus-outline-offset: 2px;
}

.gdpr-cookieconsent-settings {
    overflow-x: auto;
    background-color: var(--cookieconsent-bg-color);
    position: relative;

    & .hidden {
        display: none;
    }

    &__content {
        max-width: var(--cookieconsent-width);
        width: 100%;
        margin: 0 auto;
        padding-block-start: calc(var(--cookieconsent-spacing) * 2);
        padding-inline: calc(var(--cookieconsent-spacing) * 2);
        padding-top: calc(var(--cookieconsent-spacing) * 4);

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media only screen and (max-width: 1224px) {
            padding-inline: calc(var(--cookieconsent-spacing) * 3);
        }

        &__info {
            flex: 1 0;

            h2 {
                font-size: 22px;
                line-height: 22px;
                padding-left: 0px;
                font-family: "Hind", sans-serif;
                font-weight: normal;
                letter-spacing: 4px;
                padding-bottom: 20px;
            }

            &__description {
                margin-top: calc(var(--cookieconsent-spacing) * 2);
                font-size: 0.8rem;
                p {
                    padding-left: 0px;
                    font-size: 0.8rem;
                }
                &--main {}
                &--settings {}
            }
        }

        &__cta {
            min-width: 20%;
            display: flex;
            flex-direction: column;
            margin-left: var(--cookieconsent-spacing);

            @media only screen and (max-width: 1224px) {
                flex: 0 0 100%;
                width: 100%;
                margin: calc(var(--cookieconsent-spacing) * 2) 0 0;
            }
            :where(button.gdpr-cc-button) {
                all: unset; //reset site stlyes for button element and only use our styles
            }
            #gdpr-cc-btn-accept-necessary {
                background-color: rgb(0, 163, 93);
            }
            .gdpr-cc-button {
                color: var(--cookieconsent-button-secondary-text-color);
                width: 100%;
                text-align: center;
                background: var(--cookieconsent-button-primary-color);
                border-radius: var(--cookieconsent-button-borderradius);
                cursor: pointer;
                white-space: nowrap;
                box-sizing: border-box;
                vertical-align: middle;
                margin: 0 0 0.5rem 0;
                padding: 0.85em 1em;
                border: 1px solid transparent;
                border-radius: 10px;
                transition: background-color 0.25s ease-out, color 0.25s ease-out;
                font-family: "Hind", sans-serif;
                font-size: 0.9rem;
                line-height: 1;
                padding-inline: calc(var(--cookieconsent-spacing) / 2);

                &--secondary {
                    background:  rgb(0, 163, 93);
                    color: var(--cookieconsent-button-secondary-text-color);
                }

                & + .gdpr-cc-button {
                    margin-top: calc(var(--cookieconsent-spacing) / 2);
                    @media only screen and (max-width: 1224px) {
                        margin-top: var(--cookieconsent-spacing);
                    }
                }

                &:hover {
                    filter: brightness(0.9)
                }
                &:focus {
                    outline: var(--cookieconsent-focus-outline);
                    outline-offset: var(--cookieconsent-focus-outline-offset);
                }
            }
        }

        &__settings {
            width: 100%;
            margin-top: calc(var(--cookieconsent-spacing) * 1);
        }

        &__links {
            margin: calc(var(--cookieconsent-spacing) * 1) 0;
            width: 100%;
            font-weight: bold;
            span {
                padding: 0 5px;
            }
        }
    }

    &__close {
        position: absolute;
        top: calc(var(--cookieconsent-spacing) * 1);
        right: calc(var(--cookieconsent-spacing) * 1);
    }
}

.gdpr-cookieconsent-setting-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 1rem;

    background-color: var(--cookieconsent-group-bg-color);
    padding: calc(var(--cookieconsent-spacing) * 1.5);

    &__switch,
    &__description,
    &__details {
        flex: 1 0 100%;
    }

    &__description,
    &__details{
        margin-top: var(--cookieconsent-spacing);
        font-size: 0.8rem;
    }

    &__cookies {
        flex: 1 0 100%;
        margin-top: calc(var(--cookieconsent-spacing) * 1);
    }

    & + & {
        margin-top: calc(var(--cookieconsent-spacing) * 1);
    }

    &__details {
        text-align: right;
        color: var(--cookieconsent-theme-color);
        font-weight: bold;
        line-height: 1;

        &:hover {
            cursor: pointer;
            color: var(--cookieconsent-theme-color);
        }
        &--close,
        &--open {}
    }
}

.gdpr-cookieconsent-cookie {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1 0 100%;

    &__attributes {
        flex: 0 0 100%;
        width: 100%;
    }

    &__switch {
        flex: 1 0 100%;
    }

    & + & {
        margin-top: calc(var(--cookieconsent-spacing) * 1);
    }
}

.gdpr-cookieconsent-cookie-attribute {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8rem;

    &__value {}

    &__label {
        flex-basis: 30%;
    }

}

.gdpr-cookieconsent-switch {
    all: unset;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    --cookieconsent-switch-thumb-size: 1rem;
    --cookieconsent-switch-thumb-color: rgb(255 255 255);
    --cookieconsent-switch-thumb-highlight-color: rgba(0 0 0 / 0.25);
    --cookieconsent-switch-length: calc(var(--cookieconsent-switch-thumb-size) * 2);
    --cookieconsent-switch-padding: 3px;
    --cookieconsent-switch-inactive-color: rgb(204 204 204);
    --cookieconsent-switch-active-color: var(--cookieconsent-theme-color);
    --cookieconsent-switch-thumb-size-small: 1rem;
    --cookieconsent-switch-outline: var(--cookieconsent-focus-outline);
    --cookieconsent-switch-outline-offset: var(--cookieconsent-focus-outline-offset);

    &--group {
        font-size: 1rem;
        font-family: "Hind", sans-serif;
    }
    &--cookie {
        min-width: 50%;
        font-size: 0.8rem;
        line-height: 1.5;
        /* font-weight: bold; */
        --cookieconsent-switch-thumb-size: var(--cookieconsent-switch-thumb-size-small);
    }

    & > input {
        all: unset;
        appearance: none;
        position: relative;
        inline-size: var(--cookieconsent-switch-length);
        block-size: var(--cookieconsent-switch-thumb-size);
        padding: var(--cookieconsent-switch-padding);
        background: var(--cookieconsent-switch-inactive-color);
        transition: background-color .25s ease;
        border-radius: var(--cookieconsent-switch-length);
        border: none;
        box-sizing: content-box;
        --_cookieconsent-switch-thumb-position: 0%;

        /* Thumb */
        &::before {
            --_cookieconsent-switch-highlight-size: 0;

            content: "";
            border-radius: 50%;
            box-shadow: 0 0 0 var(--_cookieconsent-switch-highlight-size) var(--cookieconsent-switch-thumb-highlight-color);
            cursor: pointer;
            pointer-events: auto;
            position: absolute;
            inline-size: var(--cookieconsent-switch-thumb-size);
            block-size: var(--cookieconsent-switch-thumb-size);
            background: var(--cookieconsent-switch-thumb-color, white);
            transform: translateX(var(--_cookieconsent-switch-thumb-position));
            transition: transform 0.25s, box-shadow 0.1s;
        }

        &:not(:disabled):hover::before {
            @media (hover: none) {
                --_cookieconsent-switch-highlight-size: 0;
            }
            --_cookieconsent-switch-highlight-size: .5rem;
        }
        &:checked {
            background: var(--cookieconsent-switch-active-color);
            --_cookieconsent-switch-thumb-position: calc(var(--cookieconsent-switch-length) - 100%);
        }
        &:disabled {
            cursor: not-allowed;

            &::before {
                cursor: not-allowed;
            }
        }
        &:focus {
            outline: var(--cookieconsent-switch-outline);
            outline-offset: var(--cookieconsent-switch-outline-offset);
        }
    }
}