$fa-font-path: '../Fonts';
@import "Fonts";
@import "Components";
@import "cookieConsent";

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {

  /* open-sans-300 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../Fonts/open-sans-v18-latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/open-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/open-sans-v18-latin-300.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/open-sans-v18-latin-300.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/open-sans-v18-latin-300.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/open-sans-v18-latin-300.svg#OpenSans') format('svg');
    /* Legacy iOS */
  }

  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../Fonts/open-sans-v18-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/open-sans-v18-latin-regular.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/open-sans-v18-latin-regular.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/open-sans-v18-latin-regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg');
    /* Legacy iOS */
  }

  /* open-sans-italic - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../Fonts/open-sans-v18-latin-italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/open-sans-v18-latin-italic.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/open-sans-v18-latin-italic.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/open-sans-v18-latin-italic.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/open-sans-v18-latin-italic.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/open-sans-v18-latin-italic.svg#OpenSans') format('svg');
    /* Legacy iOS */
  }

  /* open-sans-700 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../Fonts/open-sans-v18-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/open-sans-v18-latin-700.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/open-sans-v18-latin-700.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/open-sans-v18-latin-700.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/open-sans-v18-latin-700.svg#OpenSans') format('svg');
    /* Legacy iOS */
  }

  /* open-sans-condensed-300 - latin */
  @font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../Fonts/open-sans-condensed-v15-latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/open-sans-condensed-v15-latin-300.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/open-sans-condensed-v15-latin-300.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/open-sans-condensed-v15-latin-300.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/open-sans-condensed-v15-latin-300.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/open-sans-condensed-v15-latin-300.svg#OpenSansCondensed') format('svg');
    /* Legacy iOS */
  }

  /* open-sans-condensed-700 - latin */
  @font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../Fonts/open-sans-condensed-v15-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/open-sans-condensed-v15-latin-700.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/open-sans-condensed-v15-latin-700.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/open-sans-condensed-v15-latin-700.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/open-sans-condensed-v15-latin-700.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/open-sans-condensed-v15-latin-700.svg#OpenSansCondensed') format('svg');
    /* Legacy iOS */
  }

  /* open-sans-condensed-300italic - latin */
  @font-face {
    font-family: 'Open Sans Condensed';
    font-style: italic;
    font-weight: 300;
    src: url('../Fonts/open-sans-condensed-v15-latin-300italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/open-sans-condensed-v15-latin-300italic.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/open-sans-condensed-v15-latin-300italic.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/open-sans-condensed-v15-latin-300italic.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/open-sans-condensed-v15-latin-300italic.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/open-sans-condensed-v15-latin-300italic.svg#OpenSansCondensed') format('svg');
    /* Legacy iOS */
  }

  /* hind-300 - latin */
  @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 300;
    src: url('../Fonts/hind-v11-latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/hind-v11-latin-300.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/hind-v11-latin-300.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/hind-v11-latin-300.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/hind-v11-latin-300.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/hind-v11-latin-300.svg#Hind') format('svg');
    /* Legacy iOS */
  }

  /* hind-regular - latin */
  @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    src: url('../Fonts/hind-v11-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/hind-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/hind-v11-latin-regular.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/hind-v11-latin-regular.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/hind-v11-latin-regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/hind-v11-latin-regular.svg#Hind') format('svg');
    /* Legacy iOS */
  }

  /* hind-regular - latin */
  @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    src: url('../Fonts/hind-v11-latin-500.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/hind-v11-latin-500.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/hind-v11-latin-500.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/hind-v11-latin-500.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/hind-v11-latin-500.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/hind-v11-latin-500.svg#Hind') format('svg');
    /* Legacy iOS */
  }

  /* hind-700 - latin */
  @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 700;
    src: url('../Fonts/hind-v11-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../Fonts/hind-v11-latin-700.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../Fonts/hind-v11-latin-700.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../Fonts/hind-v11-latin-700.woff') format('woff'),
      /* Modern Browsers */
      url('../Fonts/hind-v11-latin-700.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../Fonts/hind-v11-latin-700.svg#Hind') format('svg');
    /* Legacy iOS */
  }

  html {
    font-family: 'Hind';
  }

  b,
  strong {
    font-family: 'Hind';
  }

  h1 {
    font-family: 'Open Sans';

  }

  h2 {
    font-family: 'Open Sans';
    letter-spacing: 4px;
    font-size: 2.5rem;
    line-height: 2.375rem;

    @screen lg {
      font-size: 3.5rem;
      line-height: 4.25rem;
    }
  }

  h4 {
    font-family: "Open Sans";
    font-size: 1.6rem;
    font-weight: 300;
    border-bottom: 1px #707070 solid;
    padding-bottom: 16px;
    margin-bottom: 20px;
    letter-spacing: 2px;
    line-height: 36px;
  }

  a {
    @apply text-green
  }

}

svg {
  color: #fff;
}

p {
  padding-bottom: 1rem;

  &:last-child {
    padding-bottom: 0;
  }
}

.text-container {
  ul {
    margin-left: 1.25rem;
    list-style-type: circle;
  }

  ol {
    margin-left: 1.25rem;
    list-style-type: decimal;
  }
}

@layer components {
  .container {
    padding: 1rem;
  }

  ken-burns-carousel {
    img {
      filter: var(--img-filter);
      object-fit: contain;
    }

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .ken-burns {
    position: relative;
    height: 0;
    padding-bottom: 45%;
  }

  ken-burns-carousel,
  pre {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
  }
}

footer {
  .ck-editor__editable_inline {
    display: inline-block;
  }
}